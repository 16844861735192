import ReactDOM from "react-dom/client";
import React from "react";
import App from "./js/App";
import Layout from "./js/components/Layout";
import InvalidPage from "./js/pages/404";
import Contact from "./js/pages/Contact";
import Blog from "./js/pages/Blog";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<App />} />
        <Route path="contact" element={<Contact />} />
        <Route path="blog" element={<Blog />} />
        <Route path="*" element={<InvalidPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
