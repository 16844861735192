import React from "react";
import "../../scss/css/styles.css";
import "../../scss/css/Contact.css"
import { motion } from "framer-motion";
export default function Contact() {
  return (
    <>
      <div className="contactContainer">
        <h1> Contact </h1>
        <div className="contactContent">
          <ul>
            <li>
              <motion.a className="contactLink" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}href="mailto:ernestbarrington3@gmail.com">
                Email
              </motion.a>
            </li>
            <li>
              <motion.a className="contactLink" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} href="https://gitlab.com/embarrington3">
                Gitlab
              </motion.a>
            </li>
            <li>
              <motion.a className="contactLink" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} href="">
                Github
              </motion.a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
