import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar.js";
// import Footer from "./Footer.js";

export default function Layout() {
  return (
    <>
      <Navbar />
      {/* <Footer/> */}
      <Outlet />
      
    </>
  );
}
