import React from "react";
import "../../scss/css/Navbar.css";
import { motion } from "framer-motion";

export default function Navbar() {
  return (
    <div className="Navbar">
      <motion.a whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} href="/">
        <h1> Ernest Barrington</h1>
      </motion.a>
      <ul>
        <li>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            href="/#About"
          >
            About
          </motion.a>
        </li>
        <li>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            href="/#Projects"
          >
            Projects
          </motion.a>
        </li>
        <li>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            href="/contact"
          >
            Contact
          </motion.a>
        </li>
      </ul>
    </div>
  );
}
