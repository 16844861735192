import React from "react";
import "../../../scss/css/styles.css";
import { motion } from "framer-motion";

export default function Projects() {
  return (
    <div className="projectsWrapper">
      <h2>Projects</h2>
      <motion.div className="Project" whileHover={{ scale: 1.1 }}>
        <h3> Website</h3>
        <p> {"The website you're currently looking at."}</p>
        <p>{"Built with: React, Javascript, and SCSS."}</p>
        <a href="https://gitlab.com/embarrington3/embarrington3.gitlab.io">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="info-button"
          >
            View on Gitlab
          </motion.button>
        </a>
      </motion.div>
      <motion.div className="Project" whileHover={{ scale: 1.1 }}>
        <h3> Homepage </h3>
        <p>{"Simple home page built with CSS, HTML, and JS."}</p>
        <a href="https://gitlab.com/embarrington3/homepage">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="info-button"
          >
            View on Gitlab
          </motion.button>
        </a>
      </motion.div>
      <motion.div className="Project" whileHover={{ scale: 1.1 }}>
        <h3> Projectile Motion Calculator</h3>
        <p> {"Projectile motion calculator made with R."}</p>
        <a href="https://gitlab.com/embarrington3/projectile-motion-calculator">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="info-button"
          >
            View on Gitlab
          </motion.button>
        </a>
      </motion.div>
    </div>
  );
}
