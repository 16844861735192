import React from "react";

export default function Blog() {
  return (
    <>
      <div className="Blog">
        <h1> Blog </h1>
        <div className="Articles"></div>
      </div>
    </>
  );
}
