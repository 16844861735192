import React from "react";
import "../../../scss/css/styles.css";
export default function About() {
  return (
    <div className="About">
      <h2> About</h2>
      <section>
        <p>
          I am Ernest Barrington, an aspiring programmer. At the moment, my only
          programming experience comes from my time in FTC, FRC, and the various
          projects that are on my gitlab profile.
        </p>
      </section>
      <h3>Experience</h3>
      <section>
        <p>
          FTC: Programmer for FTC #8798 Aluminati Industries for four years (2019-2023).
        </p>
        <p>FRC: Programmer for FRC #6322 Oneclay A.R.M.O.R.Y for four years (2019-2023).</p>
        <p>
          In both programs, we used Kotlin and Java to perform the tasks
          presented.
        </p>
      </section>
    </div>
  );
}
