import React from "react";
import { motion } from "framer-motion";
import "../../scss/css/styles.css";
export default function InvalidPage() {
  return (
    <div className="InvalidPage">
      <h1> Error Code: 404 </h1>
      <h2> Looks like you ended up on the wrong page.</h2>
      <motion.a whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} href="/">
        <motion.button
          className="return-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Return Home
        </motion.button>
      </motion.a>
    </div>
  );
}
