import React from "react";
import "../scss/css/styles.css";
import About from "./components/main/About.js";
import Projects from "./components/main/Projects.js";
// import Blob from "./components/main/Blob";
function App() {
  return (
    <div className="App">
      <About />
      <Projects/>
    </div>
  );
}

export default App;
